import ContainerEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Basic/Container/ContainerEditorCmp.vue";
import FragmentEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Containers/Fragment/FragmentEditorCmp";
import {FragmentProcessor} from "app-to-up-vue-renderer/src/renderer/components/Containers/Fragment/FragmentProcessor";
import ButtonEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Basic/Button/ButtonEditorCmp";
import TextEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Basic/Text/TextEditorCmp.vue";
import FormEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Form/Form/FormEditorCmp";
import {FormProcessor} from "app-to-up-vue-renderer/src/renderer/components/Form/Form/FormProcessor";
import SubmitEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Form/SubmitEditorCmp.vue";
import InputEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Form/InputEditorCmp";
import CheckBoxEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Form/CheckBoxEditorCmp.vue";
import {LogicConditionProcessor} from "a2u-renderer-common/src/processors/Logic/Code/LogicConditionProcessor";
import {DeviceNavigatorProcessor} from "app-to-up-vue-renderer/src/renderer/components/Device/DeviceNavigatorProcessor";
import {StorageSetValueProcessor} from "a2u-renderer-common/src/processors/Storage/StorageSetValueProcessor";
import RichTextEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Basic/ReachText/RichTextEditorCmp.vue";
import LottieEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Basic/Lottie/LottieEditorCmp.vue";
import {InAppPurchaseProcessor} from "app-to-up-vue-renderer/src/renderer/components/Monetization/InAppPurchaseProcessor";
import InAppProductsListEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Monetization/InAppProductsListEditorCmp.vue";
import {HasInAppPurchaseProcessor} from "app-to-up-vue-renderer/src/renderer/components/Monetization/HasInAppPurchaseProcessor";
import ImageEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Basic/Image/ImageEditorCmp.vue";
import IconEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Basic/Icon/IconEditorCmp.vue";
import VideoEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Basic/Video/VideoEditorCmp.vue";
import {VideoProcessor} from "app-to-up-vue-renderer/src/renderer/components/Basic/Video/VideoProcessor";
import DiagramComponentEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Logic/DiagramComponent/DiagramComponentEditorCmp.vue";
import {DiagramComponentProcessor} from "app-to-up-vue-renderer/src/renderer/components/Logic/DiagramComponent/DiagramComponentProcessor";
import WidgetRendererEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Navigation/WidgetRendererEditorCmp.vue";
import {CustomEventProcessor} from "app-to-up-vue-renderer/src/renderer/components/Logic/Code/CustomEventProcessor";
import {SignUpFunctionProcessor} from "app-to-up-vue-renderer/src/renderer/components/Auth/SignUpFunctionProcessor";
import WidgetEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Containers/Widget/WidgetEditorCmp.vue";
import {WidgetProcessor} from "app-to-up-vue-renderer/src/renderer/components/Containers/Widget/WidgetProcessor";
import AppHeaderEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Navigation/AppHeaderEditorCmp.vue";
import RepeaterEditorCmp from "app-to-up-vue-renderer/src/renderer/components/DataViews/ListView/RepeaterEditorCmp.vue";
import {RepeaterProcessor} from "app-to-up-vue-renderer/src/renderer/components/DataViews/ListView/RepeaterProcessor";
import SeparatorEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Basic/Separator/SeparatorEditorCmp.vue";
import LinearProgressEditorCmp from "app-to-up-vue-renderer/src/renderer/components/Basic/Progress/LinearProgressEditorCmp.vue";
import {RegularDialogProcessor} from "app-to-up-vue-renderer/src/renderer/components/Navigation/RegularDialogProcessor";
import {FakeLogicProcessor} from "a2u-renderer-common/src/processors/Logic/Code/FakeLogicProcessor";
import {DelayedOperationProcessor} from "a2u-renderer-common/src/processors/Logic/Code/DelayedOperationProcessor";
import {AbTestProcessor} from "a2u-renderer-common/src/processors/Analytics/AbTestProcessor";
import IntersectionEditorCmp from 'app-to-up-vue-renderer/src/renderer/components/Analytics/Intersection/IntersectionEditorCmp.vue';
import {MarketingEventProcessor} from 'app-to-up-vue-renderer/src/renderer/components/Analytics/MarketingEventProcessor';
import {MailingListManagerProcessor} from 'app-to-up-vue-renderer/src/renderer/components/Logic/MailingListManager/MailingListManagerProcessor';
import {ApiRequestProcessor} from 'a2u-renderer-common/src/processors/API/ApiRequestProcessor';
import {RecaptchaProcessor} from 'app-to-up-vue-renderer/src/renderer/components/Form/RecaptchaProcessor';
                export const designerComponentsList = [
                        {
        title: "Container",
        type: "Container",
        allowed: ['*'],
        component: ContainerEditorCmp,
    },
    {
        title: "Fragment",
        type: "Fragment",
        allowed: ['*'],
        component: FragmentEditorCmp,
        processor: FragmentProcessor
    },
    {
        title: "Button",
        type: "Button",
        allowed: ['*'],
        component: ButtonEditorCmp,
    },
    {
        title: "Text",
        type: "Text",
        allowed: ['*'],
        component: TextEditorCmp,
    },
    {
        title: "Form",
        type: "Form",
        allowed: ['*'],
        component: FormEditorCmp,
        processor: FormProcessor
    },
    {
        title: "Submit button",
        type: "SubmitButton",
        allowed: ['*'],
        component: SubmitEditorCmp
    },
    {
        title: "Text input",
        type: "TextInput",
        allowed: ['*'],
        component: InputEditorCmp,
    },
    {
        title: "Checkbox",
        type: "CheckBox",
        allowed: ['*'],
        component: CheckBoxEditorCmp,
    },
    {
        title: "Logic condition",
        type: "LogicCondition",
        allowed: ['*'],
        processor: LogicConditionProcessor
    },
    {
        title: "Device navigator",
        type: "DeviceNavigator",
        allowed: ['*'],
        processor: DeviceNavigatorProcessor
    },
    {
        title: "Storage set value",
        type: "StorageSetValue",
        allowed: ['*'],
        processor: StorageSetValueProcessor
    },
    {
        title: "Rich text",
        type: "RichText",
        allowed: ['*'],
        component: RichTextEditorCmp,
    },
    {
        title: "LottieAnimation",
        type: "Lottie",
        allowed: ['*'],
        component: LottieEditorCmp
    },
    {
        title: "In app purchase",
        type: "InAppPurchase",
        allowed: [],
        processor: InAppPurchaseProcessor
    },
    {
        title: "In app purchases list",
        type: "InAppProductsList",
        allowed: [],
        component: InAppProductsListEditorCmp
    },
    {
        title: "Has in app purchase",
        type: "HasInAppPurchase",
        allowed: [],
        processor: HasInAppPurchaseProcessor
    },
    {
        title: "Image",
        type: "Image",
        allowed: ['*'],
        component: ImageEditorCmp,
    },
    {
        title: "Icon",
        type: "Icon",
        allowed: ['*'],
        component: IconEditorCmp,
    },
    {
        title: "Video",
        type: "Video",
        allowed: ['*'],
        component: VideoEditorCmp,
        processor: VideoProcessor
    },
    {
        title: "Diagram component",
        type: "DiagramComponent",
        allowed: ['*'],
        processor: DiagramComponentProcessor,
        component: DiagramComponentEditorCmp
    },
    {
        title: "Widget renderer",
        type: "WidgetRenderer",
        allowed: ['*'],
        component: WidgetRendererEditorCmp
    },
    {
        title: "Custom event",
        type: "CustomEvent",
        allowed: ['*'],
        processor: CustomEventProcessor
    },
    {
        title: "Sign up function",
        type: "SignUpFunction",
        allowed: [],
        processor: SignUpFunctionProcessor
    },
    {
        title: "Widget",
        type: "Widget",
        allowed: [],
        component: WidgetEditorCmp,
        processor: WidgetProcessor
    },
    {
        title: "App header",
        type: "AppHeader",
        allowed: ['*'],
        component: AppHeaderEditorCmp
    },
    {
        title: "Repeater",
        type: "Repeater",
        allowed: ['*'],
        component: RepeaterEditorCmp,
        processor: RepeaterProcessor
    },
    {
        title: "Separator",
        type: "Separator",
        allowed: ['*'],
        component: SeparatorEditorCmp
    },
    {
        title: "Linear progress",
        type: "LinearProgress",
        allowed: ['*'],
        component: LinearProgressEditorCmp
    },
    {
        title: "Regular dialog",
        type: "RegularDialog",
        allowed: ['*'],
        processor: RegularDialogProcessor
    },
    {
        title: "Logic block",
        type: "FakeLogic",
        allowed: ['*'],
        processor: FakeLogicProcessor
    },
    {
        title: "Delayed operation",
        type: "DelayedOperation",
        allowed: ['*'],
        processor: DelayedOperationProcessor
    },
    {
        title: "AtTest",
        type: "AbTest",
        allowed: ['*'],
        processor: AbTestProcessor
    },
    {
        title: "Intersection",
        type: "Intersection",
        allowed: ['*'],
        component: IntersectionEditorCmp,
    },
    {
        title: "Marketing event",
        type: "MarketingEvent",
        allowed: ['*'],
        processor: MarketingEventProcessor,
    },
    {
        title: "Mailing list manager",
        type: "MailingListManager",
        allowed: ['*'],
        processor: MailingListManagerProcessor,
    },
    {
        title: "API Request",
        type: "ApiRequest",
        allowed: ['*'],
        processor: ApiRequestProcessor,
    },
    {
        title: "Google reCAPTCHA",
        type: "ReCaptcha",
        allowed: ['*'],
        processor: RecaptchaProcessor,
    }
                ]